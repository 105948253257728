import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
  <SEO title="404: Not found" />
    <div className="row main-content container not-found" id="mainContent">
      <div className="row main-content__box center-asset">
        <h1>Page not found</h1>
        <p>Want to <a href="/">return to the homepage?</a></p>
      </div>
    </div>
  </Layout>
  )
  
  export default NotFoundPage
  